import React, { useState, useEffect } from 'react';
import { getUserEventsApi } from '../api/api';

const EventsTable = () => {
  const [events, setEvents] = useState([]); // Состояние для событий
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const [error, setError] = useState(null); // Состояние ошибки

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    getUserEventsApi(
      (data) => {
        setEvents(data); // Устанавливаем полученные события
        console.log(data);
      },
      (err) => {
        setError(err.error || 'Ошибка при загрузке данных'); // Устанавливаем сообщение об ошибке
      }
    ).finally(() => {
      setIsLoading(false); // Завершаем состояние загрузки
    });
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <div className="max-w-7xl mx-auto bg-white shadow-md rounded-3xl p-4">
        <h2 className="text-xl font-semibold mb-4">События</h2>
        {isLoading ? (
          <p className="text-center text-gray-500">Загрузка...</p>
        ) : error ? (
          <p className="text-center text-red-500">Ошибка: {error}</p>
        ) : events.length === 0 ? (
          <p className="text-center text-gray-500">События отсутствуют</p>
        ) : (
          <table className="w-full border-collapse">
            <thead>
              <tr className="text-left text-gray-500 text-sm uppercase border-b border-gray-200">
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">Пользователь</th>
                <th className="py-2 px-4">Адрес</th>
                <th className="py-2 px-4">Код</th>
                <th className="py-2 px-4">Категория</th>
                <th className="py-2 px-4">Уровень</th>
                <th className="py-2 px-4">Сообщение</th>
                <th className="py-2 px-4">Время</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event, index) => (
                <tr key={event.id} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition-colors`}>
                  <td className="py-2 px-4 text-gray-700">{event.id}</td>
                  <td className="py-2 px-4 text-gray-700">{event.user.fio}</td>
                  <td className="py-2 px-4 text-gray-700">
                    {event.device.address}, кв. {event.device.apartment}
                  </td>
                  <td className="py-2 px-4 text-gray-700">{event.code}</td>
                  <td className="py-2 px-4 text-gray-700">{event.category}</td>
                  <td className="py-2 px-4 text-gray-700">{event.level}</td>
                  <td className="py-2 px-4 text-gray-700">{event.payload.message}</td>
                  <td className="py-2 px-4 text-gray-700">{new Date(event.occurred).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default EventsTable;
