import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const Login = () => {
  const [searchParams] = useSearchParams();
  const [params, setParams] = React.useState({
    username: '',
    password: '',
  });

  const [passwordType, setPasswordType] = React.useState('password');
  const formRef = React.useRef(null);

  React.useEffect(() => {
    // Устанавливаем параметры из query
    setParams((prevParams) => ({
      ...prevParams,
      redirect_uri: searchParams.get('redirect_uri'),
      client_id: searchParams.get('client_id'),
      state: searchParams.get('state'),
    }));
  }, [searchParams]);

  const handlePasswordVisibilityToggle = () => {
    setPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Предотвращаем стандартное поведение React
    if (formRef.current) {
      formRef.current.submit(); // Отправляем форму через DOM API
    }
  };

  return (
    <div className="flex w-full min-h-screen p-4 items-start justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
      <div className="w-full max-w-lg">
        <form
          ref={formRef}
          method="POST"
          action={`https://integrations.meridian.pro/login?redirect_uri=${params.redirect_uri}&client_id=${params.client_id}&state=${params.state || ''}`}
          className="bg-white shadow-2xl rounded-3xl border-b border-x border-gray-300 px-8 pt-6 pb-8 mb-4">
          <div className="mb-2">
            <p className="text-gray-800 col-span-5 mr-3 text-center text-xl font-bold">Выполните вход в Yandex через аккаунт Mira</p>
          </div>
          <div className="mb-2 justify-center items-center flex">
            <img src="https://instructions.mira.cloud/api/v1/dev/images/1713816313537.jpg" className="h-20 max-w-full rounded-full" alt="" />
          </div>
          {/* Поле для ввода имени пользователя */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm ml-2 mb-2" htmlFor="username">
              Логин
            </label>
            <input
              value={params.username}
              onChange={(e) => setParams({ ...params, username: e.target.value })}
              className="appearance-none border border-gray-300 pl-4 rounded-full w-full h-10 px-3 text-gray-700 leading-tight focus:outline-none"
              id="username"
              type="text"
              placeholder="someone@example.com"
              autoComplete="username"
              name="username"
            />
          </div>
          {/* Поле для ввода пароля */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm ml-2 mb-2" htmlFor="password">
              Пароль
            </label>
            <div className="flex flex-row h-10">
              <input
                value={params.password}
                onChange={(e) => setParams({ ...params, password: e.target.value })}
                className="text-gray-800 leading-tight pl-4 h-10 border-gray-300 border-y border-l rounded-full rounded-r-none w-full py-1 px-2 outline-none"
                type={passwordType}
                placeholder="********"
                autoComplete="current-password"
                name="password"
              />
              <span className="flex items-center select-none bg-white rounded-full rounded-l-none border-gray-300 border-y border-r px-3 font-bold text-grey-100">
                <RemoveRedEyeOutlinedIcon className="w-6 h-6 fill-gray-500 hover:fill-black" onClick={handlePasswordVisibilityToggle} />
              </span>
            </div>
          </div>
          {/* Кнопка отправки */}
          <div className="mb-4">
            <button type="submit" onClick={handleSubmit} className="btn btn-primary w-full">
              Войти
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
