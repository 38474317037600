import axios from 'axios';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, DEFAULT_REFRESH_INTERVAL_MS, REFRESH_TIMESTAMP_KEY, USE_BEARER } from '../constants2';
import { setAuthorizedEvent } from '../App';

var refrash_int_inst;

const axiosRequest = axios.create({
  //withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL2,
});

const setBearerHeaders = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  return USE_BEARER ? { headers: { Authorization: 'Bearer ' + token } } : {};
};

const handleError = async (err) => {
  if (err?.response?.status === 401) {
    refreshTokens({ refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY) });
  }
};

export const refreshTokens = async (refresh_token) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .post('auth/refresh', refresh_token, headers)
    .then((res) => {
      updateTokens(res.data.access_token, res.data.refresh_token);
      setAuthorizedEvent(true);
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        setAuthorizedEvent(false);
      }
      var msg = err?.response?.data;
      if (msg) {
        console.log(JSON.stringify(msg));
      } else {
        console.log('server missing or no internet');
      }
    });
};

const updateTokens = async (access_token, refresh_token) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
  localStorage.setItem(REFRESH_TIMESTAMP_KEY, (Date.now() + DEFAULT_REFRESH_INTERVAL_MS).toString());
};

export const startRefrashTokenTask2 = async () => {
  clearInterval(refrash_int_inst);
  refrash_int_inst = setInterval(() => {
    refreshTokens({ refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY) });
  }, DEFAULT_REFRESH_INTERVAL_MS);
};

export const deleteRefrashTokenTask2 = async () => {
  clearInterval(refrash_int_inst);
};

export const saveUser = async (user, dataCb, errCb) => {
  await axiosRequest
    .post('auth/save', user)
    .then((res) => {
      dataCb(res);
      updateTokens(res.data.access_token, res.data.refresh_token);
    })
    .catch((err) => {
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const addIntegrationApi2 = async (integConf, integrationKey, deviceId, dataCb, errCb) => {
  const headers = setBearerHeaders();
  headers.params = {
    deviceId,
  };
  await axiosRequest
    .post('integrations/' + integrationKey, integConf, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const getIntegrationsApi2 = async (dataCb, errCb, deviceId) => {
  var headers = setBearerHeaders();
  headers.params = {
    deviceId,
  };
  await axiosRequest
    .get('integrations', headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const delIntegrationApi2 = async (id, integrationKey, deviceId, dataCb, errCb) => {
  const headers = setBearerHeaders();
  headers.params = {
    deviceId,
  };
  await axiosRequest
    .delete('integrations/' + integrationKey + '/' + id, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const delDevIntegrationApi2 = async (deviceId, dataCb, errCb) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .delete('integrations/' + deviceId, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const getInstructionsApi2 = async (dataCb, errCb) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .get('instructions/get', headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const addInstructionApi2 = async (inst, dataCb, errCb) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .post('instructions/add', inst, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const editInstructionApi2 = async (instr, dataCb, errCb) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .patch('instructions/edit', instr, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const getPermitApi2 = async (dataCb, errCb) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .get('instructions/permission', headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};
